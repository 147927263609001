<template>
    <div>
        <div class="my-1 d-flex justify-content-between">
            <b-button
                @click="$router.go(-1)"
                variant="outline-warning"
                class="btn-icon"
                size="sm"
            >
                <span class="mb-6">RETOUR</span>
            </b-button>

            <b-button
                v-if="$hasFonction(user, 'Faire un inventaire')"
                @click="addsInventaires"
                variant="primary"
                class="btn-icon"
                size="md"
            >
                <span class="mb-6">Faire un inventaire</span>
            </b-button>
        </div>
        <b-alert class="mt-2" :show="alertShow" variant="danger">
            <div
                class="alert-body d-flex flex justify-space-between font-weight-bolder"
            >
                <span class="mr-auto text-center">{{ alertMessage }} </span>
            </div>
        </b-alert>
        <b-card>
            <div
                class="
          d-flex
          flex-sm-row flex-column flex-wrap
          justify-content-between
          mb-1 mb-sm-0
        "
            >
                <div class="text-center text-uppercase">
                    <h6 v-if="association.dernier_inventaire">
                        Date du dernier inventaire
                    </h6>
                    <h6 v-else>Date de création</h6>
                    <b-badge class="text-uppercase ml-1" variant="success">
                        <small class="timeline-item-time text-nowrap">
                            {{ dateDernierInventaire }}
                        </small>
                    </b-badge>
                </div>
                <div class="text-center text-uppercase">
                    <h6>NOM de l'association</h6>
                    <b-badge class="text-uppercase ml-1" variant="success">
                        <small class="timeline-item-time text-nowrap">{{
                                association.name
                            }}</small>
                    </b-badge>
                </div>
                <div class="text-center text-uppercase">
                    <h6>Code de l'association</h6>
                    <b-badge class="text-uppercase ml-1" variant="success">
                        <small class="timeline-item-time text-nowrap">{{
                                association.code
                            }}</small>
                    </b-badge>
                </div>
                <!-- <div class="text-center text-uppercase">
                  <h6>Béneifice actuel</h6>
                  <b-badge class="text-uppercase ml-1" variant="success">
                    <small class="timeline-item-time text-nowrap">{{
                      $thousandSeparator(benefice)
                    }}</small>
                  </b-badge>
                </div> -->
            </div>
        </b-card>
        <div class="row">
            <b-card class="col mx-1 text-center bg-primary text-white">
                <h6 class="border-bottom mb-1 pb-1 text-white">
                    Capital à la date du {{ dateDernierInventaire }}
                </h6>
                <span>{{ $thousandSeparator(inventaireData.capital_precedent) }}</span>
            </b-card>
            <b-card class="col mx-1 text-center bg-primary text-white">
                <h6 class="border-bottom mb-1 pb-1 text-white">Bénéfice à ce jour</h6>
                <span>{{ $thousandSeparator(benefice) }}</span>
            </b-card>
            <b-card class="col mx-1 text-center bg-primary text-white">
                <h6 class="border-bottom mb-1 pb-1 text-white">
                    Total des retraits à ce jour
                </h6>
                <span>{{
                        $thousandSeparator(
                            retraitsList.reduce(function (sum, current) {
                                return sum + current.total * 1;
                            }, 0)
                        )
                    }}</span>
            </b-card>
            <b-card class="col mx-1 text-center bg-primary text-white">
                <h6 class="border-bottom mb-1 pb-1 text-white">
                    <!-- Capital à la date du {{ maxDate }} -->
                    Capital actuel
                </h6>
                <span>{{ $thousandSeparator(newCapital) }}</span>
            </b-card>
        </div>
        <div class="row">
            <b-card class="col-3 mr-2 ml-1 px-0 text-center">
                <h6
                    class="border-bottom mb-1 bg-dark rounded text-white"
                    style="padding-top: 5px; padding-bottom: 5px"
                >
                    VÉHICULES
                </h6>
                <div class="row px-1">
                    <div class="col p-0" style="font-size: 0.8em">
                        <b-badge variant="success">VENDUS</b-badge>
                        <div>{{ inventaireData.vehicules_vendus }}</div>
                    </div>

                    <div class="col p-0" style="font-size: 0.8em">
                        <b-badge variant="dark">STOCK</b-badge>
                        <div>{{ inventaireData.vehicules_en_stock }}</div>
                    </div>

                    <div class="col p-0" style="font-size: 0.8em">
                        <b-badge variant="primary">COURS</b-badge>
                        <div>{{ inventaireData.vehicules_en_cours_de_vente }}</div>
                    </div>
                </div>
            </b-card>
            <b-card class="col mr-2 px-0 text-center ml-2">
                <h6
                    class="border-bottom mb-1 bg-dark rounded text-white"
                    style="padding-top: 5px; padding-bottom: 5px"
                >
                    DETTES
                </h6>
                <div class="row px-1">
                    <div class="col p-0" style="font-size: 0.8em">
                        <b-badge variant="dark">Dédommagements</b-badge>
                        <div>{{ $thousandSeparator(inventaireData.refunds) }}</div>
                    </div>

                    <div class="col p-0" style="font-size: 0.8em">
                        <b-badge variant="dark">Carte Importateur</b-badge>
                        <div>{{ $thousandSeparator(inventaireData.total_cartes_imp) }}</div>
                    </div>

                    <div class="col p-0" style="font-size: 0.8em">
                        <b-badge variant="dark">Chargeurs</b-badge>
                        <div>{{ $thousandSeparator(inventaireData.total_chargeurs) }}</div>
                    </div>
                    <div class="col p-0" style="font-size: 0.8em">
                        <b-badge variant="dark">Frais</b-badge>
                        <div>{{ $thousandSeparator(inventaireData.total_frais) }}</div>
                    </div>
                    <div class="col p-0" style="font-size: 0.8em">
                        <b-badge variant="dark">Factures</b-badge>
                        <div>
                            {{ $thousandSeparator(inventaireData.total_generation_facture) }}
                        </div>
                    </div>
                </div>
            </b-card>
        </div>

        <div class="row">
            <b-card class="col mx-1 text-center bg-primary text-white">
                <div class="border-bottom mb-1 pb-1">
                    <h6 class="text-white">Montant en caisse</h6>
                    <!-- <b-img rounded class="icone" fluid :src="iconeMCaisse" /> -->
                </div>
                <span>{{ $thousandSeparator(inventaireData.cash) }}</span>
            </b-card>

            <b-card class="col mr-1 text-center bg-primary text-white">
                <div class="border-bottom mb-1 pb-1">
                    <h6 class="text-white">Valeur du stock</h6>
                    <!-- <b-img rounded class="icone" fluid :src="iconeValeurStock" /> -->
                </div>
                <span>{{ $thousandSeparator(inventaireData.valeur_stock) }}</span>
            </b-card>

            <b-card class="col mr-1 text-center bg-primary text-white">
                <div class="border-bottom mb-1 pb-1">
                    <h6 class="text-white">Montant dans les comptes</h6>
                    <!-- <b-img rounded class="icone" fluid :src="iconeMCompte" /> -->
                </div>
                <span>
          {{
                        $thousandSeparator(
                            accountsList.reduce(function (sum, current) {
                                return deviseLoaded?(
                                    sum +
                                    devises.find((_dev) => _dev.name == current.devise).value *
                                    current.solde
                                ):0;
                            }, 0)
                        )
                    }}
        </span>
            </b-card>

            <b-card class="col mr-1 px-0 text-center bg-primary text-white">
                <div class="border-bottom mb-1 pb-1">
                    <h6 class="text-white">Marge vente</h6>
                    <!-- <b-img rounded class="icone" fluid :src="iconeMarge" /> -->
                </div>
                <span
                >{{
                        inventaireData.benefices_vehicule_en_stock
                            ? $thousandSeparator(inventaireData.benefices_vehicule_en_stock)
                            : 0
                    }}
        </span>
            </b-card>

            <b-card class="col mr-1 px-0 text-center bg-primary text-white">
                <div class="border-bottom mb-1 pb-1">
                    <h6 class="text-white">Montant à recouvrer</h6>
                    <!-- <b-img rounded class="icone" fluid :src="iconeRecouvrement" /> -->
                </div>
                <span>{{
                        inventaireData.reste_sur_avance
                            ? $thousandSeparator(inventaireData.reste_sur_avance)
                            : 0
                    }}</span>
            </b-card>

            <b-card class="col mr-1 px-0 text-center bg-primary text-white">
                <div class="border-bottom mb-1 pb-1">
                    <h6 class="text-white">Dépenses</h6>
                    <!-- <b-img rounded class="icone" fluid :src="iconeDepenses" /> -->
                </div>
                <span>{{
                        inventaireData.depenses
                            ? $thousandSeparator(inventaireData.depenses)
                            : 0
                    }}</span>
            </b-card>
        </div>

        <!-- Associes -->
        <b-card title="Associés" class="mt-1">
            <div>
                <div
                    class="
            d-flex
            flex-sm-row flex-column flex-wrap
            justify-content-between
            mb-2
          "
                >
                    <b-button
                        class=""
                        v-if="associes.length > 0"
                        variant="success"
                        @click="associePrincipalModal"
                    >
                        Associé principal
                    </b-button>
                    <div>
                        <b-button
                            class="ml-2 text-uppercase"
                            variant="primary"
                            @click="associeModal"
                        >
                            Ajouter un associé
                        </b-button>
                    </div>
                </div>
                <b-table-simple hover medium caption-top responsive>
                    <b-thead head-variant="dark">
                        <b-tr>
                            <b-th>Nom & Prénom(s)</b-th>
                            <b-th>Capital</b-th>
                            <b-th>Retrait</b-th>
                            <!-- <b-th>Actions</b-th> -->
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr
                            v-for="(item, index) in associes"
                            :key="index"
                            v-if="item.type == 'associe'"
                        >
                            <b-th
                            >{{ item.name }}
                                <b-badge class="text-uppercase ml-1" variant="light-success">
                  <span v-if="item.id == association.associe_id">
                    principal
                  </span>
                                </b-badge>
                            </b-th>
                            <b-td>{{ $thousandSeparator(item.pivot.cap_invest) }}</b-td>
                            <b-td>
                                {{
                                    $thousandSeparator(
                                        retraitsList.find(
                                            (_retrait) => _retrait.associe == item.name
                                        )
                                            ? retraitsList.find(
                                                (_retrait) => _retrait.associe == item.name
                                            ).total
                                            : 0
                                    )
                                }}
                            </b-td>
                            <!-- <b-td>
                              <b-button
                                :id="'button-1' + item.id"
                                size="sm"
                                variant="flat-primary"
                                @click="associeDetailModal(item)"
                              >
                                <feather-icon icon="EyeIcon" />
                              </b-button>
                              <b-button
                                :id="'button-2' + item.id"
                                size="sm"
                                variant="flat-primary"
                                @click="associeEditModal(item)"
                              >
                                <feather-icon icon="FolderPlusIcon" />
                              </b-button>
                            </b-td> -->
                        </b-tr>
                    </b-tbody>
                    <b-tfoot>
                        <b-tr>
                            <b-td variant="secondary"> Total: </b-td>
                            <b-td variant="secondary">
                                <b>
                                    {{
                                        $thousandSeparator(
                                            associes.reduce(function (sum, current) {
                                                return sum + current.pivot.cap_invest * 1;
                                            }, 0)
                                        )
                                    }}</b
                                >
                            </b-td>
                            <b-td variant="secondary">
                                <b>
                                    {{
                                        $thousandSeparator(
                                            retraitsList.reduce(function (sum, current) {
                                                return sum + current.total * 1;
                                            }, 0)
                                        )
                                    }}</b
                                >
                            </b-td>
                            <!-- <b-td variant="secondary"> </b-td> -->
                        </b-tr>
                    </b-tfoot>
                </b-table-simple>
            </div>
        </b-card>

        <!-- Comptes -->
        <b-card title="Comptes" class="mt-1">
            <div
                class="
          mb-1
          d-flex
          flex-sm-row flex-column flex-wrap
          justify-content-between
        "
            >
                <div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Rechercher"
                    >
                    </b-form-input>
                </div>

                <div>
                    <b-button
                        class="ml-2 text-uppercase"
                        variant="primary"
                        @click="openAddCompte()"
                    >
                        Ajouter un compte
                    </b-button>
                </div>
            </div>
            <b-table
                id="my-table"
                responsive="sm"
                :fields="headers"
                :items="accountsList"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="filter"
                show-empty
                class="border-bottom"
            >
                <template #cell(valeur_convertie)="data">
                    {{
                        $thousandSeparator( deviseLoaded?
                            devises.find((_dev) => _dev.name == data.item.devise).value *
                            data.item.solde:0
                        )
                    }}
                </template>
                <template #cell(actions)="data">
                    <b-button
                        size="sm"
                        @click="showAccount(data.item)"
                        variant="primary"
                        class=""
                    >
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        <span></span>
                    </b-button>
                </template>

                <template #cell(solde)="data">
                    {{ $thousandSeparator(data.item.solde ? data.item.solde : 0) }}
                </template>

                <template #empty>
                    <div class="text-center">
                        <span class="text-nowrap">Aucune donnée existante</span>
                    </div>
                </template>
                <template #emptyfiltered>
                    <div class="text-center">
            <span class="text-nowrap"
            >L'élément recherché n'est pas dans la liste</span
            >
                    </div>
                </template>
            </b-table>
            <div class="row mx-1 bg-dark py-1 text-white">
                <div class="col">TOTAL</div>
                <div class="col text-right">
                    {{
                        $thousandSeparator(

                            accountsList.reduce(function (sum, current) {
                                return deviseLoaded? (
                                    sum +
                                    devises.find((_dev) => _dev.name == current.devise).value *
                                    current.solde
                                ):0;
                            }, 0)
                        )
                    }}
                </div>
            </div>
            <div class="my-2 mx-1 row">
                <!-- per-page end -->
                <div class="col">
                    <span class="text-nowrap mr-2"> Montré de 1 à </span>
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        style="width: 70px"
                    ></b-form-select>
                    <span class="text-nowrap ml-2"> de {{ totalRows }} entrées </span>
                </div>
                <!-- per-page end -->

                <!-- pagination start -->
                <b-pagination
                    class="pagination"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    aria-controls="my-table"
                >
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                </b-pagination>
                <!-- pagination end -->
            </div>
        </b-card>

        <!-- modal add start  -->
        <b-modal
            id="modal-add"
            v-model="modalAdd"
            :ok-disabled="isOperating"
            title="Ajouter un associé"
            size="lg"
            cancel-title="Annuler"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            ok-variant="primary"
            @hidden="resetModal"
            @ok="addAssocie"
            centered
        >
            <!-- form start -->
            <validation-observer ref="addForm">
                <b-form @submit.stop.prevent="addAssocie">
                    <b-row>
                        <b-col class="col-6">
                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Associés"
                                    rules="required"
                                >
                                    <label for="ci">Associés</label>
                                    <combo-box-component
                                        id="associe"
                                        :dataSource="filterAssocies"
                                        v-model="addFormulaire.associe"
                                        :fields="fieldsComboAssocies"
                                        :state="errors.length > 0 ? false : null"
                                    >
                                    </combo-box-component>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col class="col-6">
                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Capital investi"
                                    rules="required"
                                >
                                    <label for="code">Capital investi</label>
                                    <b-input-group :append="devisePrincipal.abbreviation">
                                        <cleave
                                            id="cap"
                                            v-model="addFormulaire.cap_invest"
                                            class="form-control"
                                            :raw="false"
                                            :options="numberFormat"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1 ml-1">
                        <!-- <b-card-text><strong>Liste des comptes:</strong></b-card-text> -->
                    </b-row>
                    <b-row v-for="(item, index) in items" :key="index" ref="row">
                        <b-form-group class="col">
                            <validation-provider
                                #default="{ errors }"
                                name="devise"
                                rules="required"
                            >
                                <label for="ci">Devise</label>
                                <b-form-select
                                    :id="'devise' + index"
                                    v-model="item.devise"
                                    placeholder="Devise"
                                    @change="refreshDevise(item, index, 'add')"
                                    :state="errors.length > 0 ? false : null"
                                >
                                    <b-form-select-option
                                        :value="devise.id"
                                        v-for="devise in devises"
                                        :disabled="checkIsSelected(devise.id)"
                                        :key="devise.id"
                                    >
                                        {{ devise.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- <b-form-group class="col">
                          <validation-provider
                            #default="{ errors }"
                            name="solde"
                            rules="required"
                          >
                            <label for="ci">Solde initial</label>
                            <cleave
                              id="resteInitial"
                              v-model="item.resteInitial"
                              class="form-control"
                              :raw="false"
                              :options="numberFormat"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group> -->
                        <b-form-group class="col">
                            <validation-provider
                                #default="{ errors }"
                                name="libellé"
                                rules="required"
                            >
                                <label for="ci">Libellé</label>
                                <b-form-input
                                    :id="'libelle' + index"
                                    v-model="item.libelle"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Libelle"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger"
                                >{{
                                        errorsLibelleAdd[index] ? errorsLibelleAdd[index][0] : ""
                                    }}
                                </small>
                            </validation-provider>
                        </b-form-group>
                        <!-- Remove Button -->
                        <b-col class="mb-50">
                            <b-button
                                block
                                variant="outline-danger"
                                class="mt-0 mt-md-2"
                                @click="removeItem(index, items)"
                            >
                                <feather-icon icon="XIcon" class="mr-25" />
                                <span></span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <!-- form end -->
        </b-modal>

        <!-- modal detail start  -->
        <b-modal
            id="modal-detail"
            v-model="modalDetail"
            :ok-disabled="isOperating"
            title="Détail associé"
            size="lg"
            centered
            hide-footer
        >
            <!-- form start -->
            <validation-observer ref="addForm">
                <b-form @submit.stop.prevent="handleSubmit">
                    <b-row>
                        <b-col class="col-5">
                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Associés"
                                    rules="required"
                                >
                                    <label for="ci">Associés</label>
                                    <b-form-input
                                        disabled
                                        id="code"
                                        v-model="detailFormulaire.name"
                                        placeholder="Associé"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    <!-- <small class="text-danger">{{ errorsIfu }}</small> -->
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col class="col-7">
                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Capital investi"
                                    rules="required"
                                >
                                    <label for="code">Capital investi</label>
                                    <b-form-input
                                        id="code"
                                        disabled
                                        v-model="detailFormulaire.pivot.cap_invest"
                                        placeholder="Capital investi"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1 ml-0">
                        <b-card-text><strong>Liste des comptes</strong></b-card-text>
                    </b-row>
                    <b-row
                        v-for="(item, index) in detailFormulaire.comptes"
                        :key="index"
                        ref="row"
                    >
                        <b-form-group class="col">
                            <validation-provider
                                #default="{ errors }"
                                name="devise"
                                rules="required"
                            >
                                <label for="ci">Devise</label>
                                <b-form-input
                                    id="devise"
                                    disabled
                                    v-model="item.devise"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Devise"
                                />
                            </validation-provider>
                        </b-form-group>
                        <!-- <b-form-group class="col-3">
                          <validation-provider
                            #default="{ errors }"
                            name="solde"
                            rules="required"
                          >
                            <label for="ci">Solde initial</label>
                            <b-form-input
                              disabled
                              id="resteInitial"
                              v-model="item.solde"
                              type="number"
                              placeholder="Solde initial"
                            />
                          </validation-provider>
                        </b-form-group> -->
                        <b-form-group class="col">
                            <validation-provider
                                #default="{ errors }"
                                name="libellé"
                                rules="required"
                            >
                                <label for="ci">Libellé</label>
                                <b-form-input
                                    disabled
                                    id="montant_chargeur"
                                    v-model="item.libelle"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Libelle"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <!-- <small class="text-danger">{{ errorsIfu }}</small> -->
                            </validation-provider>
                        </b-form-group>
                        <!-- voir compte Button -->
                        <b-col class="col mb-50">
                            <b-button
                                block
                                @click="showAccount(item)"
                                variant="outline-primary"
                                class="mt-0 mt-md-2"
                            >
                                VOIR LE COMPTE
                                <feather-icon icon="EyeIcon" class="ml-2 mr-25" />
                                <span></span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <!-- form end -->
        </b-modal>
        <!-- modal detail end-->

        <!-- modal edit start  -->
        <b-modal
            id="modal-edit"
            v-model="modalEdit"
            :ok-disabled="isOperating"
            title="Modifier un associé"
            size="lg"
            cancel-title="Annuler"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            ok-variant="primary"
            @hidden="resetModal"
            @ok="editAssocie"
            centered
        >
            <!-- form start -->
            <validation-observer ref="editForm">
                <b-form @submit.stop.prevent="handleSubmit">
                    <b-row>
                        <b-col class="col-12">
                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Associés"
                                    rules="required"
                                >
                                    <label for="ci">Associés</label>
                                    <b-form-input
                                        disabled
                                        id="name"
                                        v-model="editFormulaire.name"
                                        placeholder="Associés"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1 ml-1">
                        <!-- <b-card-text><strong>Liste des comptes:</strong></b-card-text> -->
                    </b-row>
                    <b-row v-for="(item, index) in editItems" :key="index" ref="row">
                        <b-form-group class="col-4">
                            <validation-provider
                                #default="{ errors }"
                                name="devise"
                                rules="required"
                            >
                                <label for="ci">Devise</label>
                                <b-form-input
                                    disabled
                                    id="devise"
                                    v-model="item.devise"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="devise"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group class="col-5">
                            <validation-provider
                                #default="{ errors }"
                                name="libellé"
                                rules="required"
                            >
                                <label for="ci">Libellé</label>
                                <b-form-input
                                    disabled
                                    id="libelle"
                                    v-model="item.libelle"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Libelle"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- Remove Button -->
                        <!-- <b-col class="mb-50">
                          <b-button
                            v-if="item.solde == 0"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeItem(index, editItems)"
                          >
                            <feather-icon icon="XIcon" class="mr-25" />
                            <span></span>
                          </b-button>
                        </b-col> -->
                    </b-row>
                    <!-- <b-row class="mb-1 ml-1">
                      <b-card-text><strong>Ajout nouveau compte:</strong></b-card-text>
                    </b-row> -->
                    <b-row
                        v-for="(item, index) in editItems2"
                        :key="'A' + index"
                        ref="row"
                    >
                        <b-form-group class="col">
                            <validation-provider
                                #default="{ errors }"
                                name="devise"
                                rules="required"
                            >
                                <label for="ci">Devise</label>
                                <b-form-select
                                    id="Devise"
                                    v-model="item.devise"
                                    placeholder="Devise"
                                    @change="refreshDevise(item, index, 'edit')"
                                    :state="errors.length > 0 ? false : null"
                                >
                                    <b-form-select-option
                                        :value="devise.id"
                                        v-for="devise in devises"
                                        :disabled="checkIsSelected(devise.id)"
                                        :key="devise.id"
                                    >
                                        {{ devise.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- <b-form-group class="col">
                          <validation-provider
                            #default="{ errors }"
                            name="solde"
                            rules="required"
                          >
                            <label for="solde">Solde initial</label>
                            <cleave
                              id="resteInitial"
                              v-model="item.resteInitial"
                              class="form-control"
                              :raw="false"
                              :options="numberFormat"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group> -->
                        <b-form-group class="col">
                            <validation-provider
                                #default="{ errors }"
                                name="libellé"
                                rules="required"
                            >
                                <label for="ci">Libellé</label>
                                <b-form-input
                                    :id="'libelleEdit' + index"
                                    v-model="item.libelle"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Libelle"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger"
                                >{{
                                        errorsLibelleEdit[index] ? errorsLibelleEdit[index][0] : ""
                                    }}
                                </small>
                            </validation-provider>
                        </b-form-group>
                        <!-- Remove Button -->
                        <b-col class="mb-50">
                            <b-button
                                block
                                variant="outline-danger"
                                class="mt-0 mt-md-2"
                                @click="removeItem(index, editItems2)"
                            >
                                <feather-icon icon="XIcon" class="mr-25" />
                                <span></span>
                            </b-button>
                        </b-col>
                    </b-row>
                    <!-- <b-row class="ml-0">
                      <b-button variant="primary" @click="repeateAgain(editItems2)">
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>Ajouter un compte</span>
                      </b-button>
                    </b-row> -->
                </b-form>
            </validation-observer>
            <!-- form end -->
        </b-modal>
        <!-- modal edit end-->

        <!-- modal add associe principal start  -->
        <b-modal
            v-model="modalAddPrincipal"
            :ok-disabled="isOperating"
            title="Définir l'associé principal"
            size="sg"
            cancel-title="Annuler"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            ok-variant="primary"
            @hidden="resetModal"
            @ok="addAssociePrincipal"
            centered
        >
            <!-- form start -->
            <validation-observer ref="addPrincipalForm">
                <b-form @submit.stop.prevent="handleSubmit">
                    <b-col class="">
                        <b-form-group>
                            <validation-provider
                                #default="{ errors }"
                                name="Associés"
                                rules="required"
                            >
                                <label for="ci">Associés</label>
                                <b-form-select
                                    id="libelle"
                                    v-model="addPrincipalFormulaire.associe"
                                    placeholder="Associés"
                                    :options="AssociationAssocie"
                                    value-field="id"
                                    text-field="name"
                                    disabled-field="isDisabled"
                                    @change="fillLabelImput"
                                    :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <!-- <small class="text-danger">{{ errorsIfu }}</small> -->
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-form>
            </validation-observer>
            <!-- form end -->
        </b-modal>
        <!-- modal add Associe principal end-->

        <b-modal
            v-model="addCompteModal"
            :ok-disabled="isOperating"
            title="Ajouter un compte"
            size="lg"
            cancel-title="Annuler"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            ok-variant="primary"
            @hidden="resetModal"
            @ok="addCompte"
            centered
        >
            <validation-observer ref="addCompteForm">
                <b-form @submit.stop.prevent="addCompte">
                    <div class="mx-1 d-flex flex-row flex-wrap justify-content-between">
                        <div class="">Compte géré par un acheteur</div>
                        <b-form-checkbox
                            class=""
                            variant="warning"
                            switch
                            inline
                            @change="checkAccountGestionnaire()"
                            v-model="accountGestionnaire"
                        >
                        </b-form-checkbox>
                        <div class="">Compte géré par un associé</div>
                    </div>
                    <hr />
                    <b-row v-if="!accountGestionnaire">
                        <b-col class="col">
                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Nom Acheteur"
                                    :rules="!accountGestionnaire ? 'required' : ''"
                                >
                                    <label for="Acheteur">Acheteur</label>
                                    <b-form-input
                                        id="Acheteur"
                                        list="nomAcheteurs"
                                        v-model="acheteurForm.name"
                                        placeholder="Nom & Prénoms"
                                        @input="checkAcheteur()"
                                        :state="errors.length > 0 ? false : null"
                                        trim
                                    />

                                    <b-form-datalist id="nomAcheteurs" :options="acheteurs">
                                    </b-form-datalist>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col class="col">
                            <b-form-group :disabled="acheteurExist">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Devise"
                                    :rules="
                    !accountGestionnaire && !acheteurExist ? 'required' : ''
                  "
                                >
                                    <label for="devise">Devise</label>
                                    <b-form-select
                                        id="libelle"
                                        v-model="acheteurForm.devise"
                                        :options="devises"
                                        value-field="id"
                                        text-field="name"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <div v-if="accountGestionnaire">
                        <div class="row">
                            <div class="col">
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Associés"
                                        :rules="accountGestionnaire ? 'required' : ''"
                                    >
                                        <label for="ci">Associé responsable</label>
                                        <model-list-select
                                            :list="AssociationAssocie"
                                            v-model="associeForm.name"
                                            option-text="name"
                                            option-value="name"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="col">
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="libelle"
                                        :rules="accountGestionnaire ? 'required' : ''"
                                    >
                                        <label for="libelle">Libellé</label>
                                        <b-form-input
                                            v-model="associeForm.libelle"
                                            :state="errors.length > 0 ? false : null"
                                            placeholder="Libelle"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="col">
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Devise"
                                        :rules="accountGestionnaire ? 'required' : ''"
                                    >
                                        <label for="devise">Devise</label>
                                        <b-form-select
                                            id="libelle"
                                            v-model="associeForm.devise"
                                            :options="devises"
                                            value-field="id"
                                            text-field="name"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
        <b-button
            style="margin: 6px;font-size: 16px"
            @click="$router.go(-1)"
            variant="outline-warning"
            class="btn-icon"
            size="sm"
        >
            <span class="mb-6">RETOUR</span>
        </b-button>
    </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
//import BCard from "@core/components/b-card/BCard.vue";
import { json2excel, excel2json } from "js2excel";
import { ModelListSelect } from "vue-search-select";
import downloadExcel from "json2excel-js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Cleave from "vue-cleave-component";
import flatPickr from "vue-flatpickr-component";
import {
    ComboBoxComponent,
    ComboBoxPlugin,
} from "@syncfusion/ej2-vue-dropdowns";

import {
    BCard,
    BTable,
    BCardText,
    BAlert,
    BModal,
    BPagination,
    BButton,
    BIconPlus,
    BIconPencilFill,
    BIconInfoCircleFill,
    BIconTrashFill,
    BIconStar,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BTooltip,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormDatalist,
    BInputGroupPrepend,
    BInputGroupAppend,
    BIconArrowClockwise,
    BInputGroup,
    BAvatar,
    BBadge,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BThead,
    BTh,
    BTd,
    BTr,
    BTfoot,
} from "bootstrap-vue";

export default {
    components: {
        BTableSimple,
        BTbody,
        BThead,
        BTh,
        BTd,
        BFormCheckbox,
        BTr,
        BTfoot,
        BBadge,
        Cleave,
        BCard,
        BTable,
        BAvatar,
        BModal,
        BMedia,
        BMediaAside,
        ModelListSelect,
        BCardText,
        BMediaBody,
        BAlert,
        BCardCode,
        BFormDatalist,
        BPagination,
        BButton,
        BTooltip,
        BIconInfoCircleFill,
        EmptyList,
        BIconPlus,
        BIconPencilFill,
        BIconTrashFill,
        BIconFileEarmarkArrowDownFill,
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BInputGroupPrepend,
        BInputGroupAppend,
        BIconArrowClockwise,
        BInputGroup,
        BFormInput,
        BCollapse,
        BFormInvalidFeedback,
        AppCollapse,
        AppCollapseItem,
        ValidationProvider,
        ValidationObserver,
        AppTimeline,
        AppTimelineItem,
        BImg,
        BFormSelectOption,
        BIconStar,
        ComboBoxComponent,
        ComboBoxPlugin,
        flatPickr,
    },
    data() {
        return {
            iconeDepenses: require("@/assets/images/icons/depenses.png"),
            iconeMarge: require("@/assets/images/icons/marge-vente.png"),
            iconeMCaisse: require("@/assets/images/icons/montant-caisse.png"),
            iconeMCompte: require("@/assets/images/icons/montant-comptes.png"),
            iconeRecouvrement: require("@/assets/images/icons/recouvrement.png"),
            iconeValeurStock: require("@/assets/images/icons/valeur-stock.png"),
            inventairesData: {
                associationToFilter: 0,
                to: "",
            },
            inventing: false,
            isSuccess: false,
            etatButtonInventaire: true,
            isOperatingInventaire: false,
            inventairesDataTo: "",
            showInventaire: false,
            showFormInventaire: true,
            add: false,
            empty: true,
            isOperating: false,
            required,
            integer,
            alpha,
            alphaDash,
            nameState: null,
            accountGestionnaire: false,
            errorsName: null,
            errorsLastDateInventaire: null,
            errorsCurrentDateInventaire: null,
            alertMessage: null,
            alertShow: false,
            devisePrincipal: {},
            errorsMontant: null,
            errorsNameModal: null,
            errorsCodeModal: null,
            acheteurForm: {},
            detailForm: {
                name: "",
                code: "",
            },
            // totalRows: 1,
            perPage: 5,
            currentPage: 1,
            acheteurs: [],
            inventaireData: {},
            associeForm: {},
            filter: null,
            pageOptions: [5, 10, 25, 50, 100, { value: 1000000, text: "Tout" }],
            headers: [
                {
                    key: "libelle",
                    label: "Libellé du compte",
                    class: "w-25",
                    sortable: true,
                },
                {
                    key: "name",
                    label: "Responsable",
                    sortable: true,
                },
                {
                    key: "solde",
                    label: "Solde",
                    sortable: true,
                },
                {
                    key: "devise",
                    label: "Devise",
                    sortable: true,
                },
                {
                    key: "valeur_convertie",
                    label: "Valeur convertie",
                    sortable: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    sortable: true,
                },
            ],
            associes: [],
            comptes: [],
            allAssocies: [],
            filterAssocies: [],
            AssociationAssocie: [],
            numberFormat: {
                numeral: true,
                delimiter: " ",
                numeralThousandsGroupStyle: "thousand",
            },
            fieldsComboAssocies: { text: "name", value: "id" },
            Excelassocies: null,
            addCompteModal: false,
            editedForm: {},
            editForm: {},
            addFormulaire: {},
            addPrincipalFormulaire: {},
            detailFormulaire: {},
            editFormulaire: {},
            detailForm: {},
            defaultForm: {},
            items: [
                {
                    prevHeight: 0,
                },
            ],
            detailItems: [],
            editItems: [],
            editItems2: [],
            ci: 0,
            frais: 0,
            chargeurs: [],
            listeChargeurs: [],
            association_id: null,
            association: {},
            modalAdd: false,
            acheteurExist: false,
            modalAddAcheteur: false,
            modalAddPrincipal: false,
            modalEdit: false,
            modalDetail: false,
            devises: [],
            AssociePrincipal: false,
            errorsLibelleAdd: [],
            errorsLibelleEdit: [],
            selectedDevises: [],
            accountsList: [],
            retraitsList: [],
            deviseLoaded : false,
            inventaireDataLoaded : false,
        };
    },
    computed: {
        dateDernierInventaire() {
            if (this.association.dernier_inventaire) {
                return this.association.dernier_inventaire;
            } else {
                return this.association.created_at;
            }
        },
        maxDate() {
            let date = new Date();
            let annee = date.getFullYear();
            let mois = ("0" + (date.getMonth() + 1)).slice(-2);
            let jour = ("0" + date.getDate()).slice(-2);
            let heure = ("0" + date.getHours()).slice(-2);
            let minute = ("0" + date.getMinutes()).slice(-2);
            date = `${jour}-${mois}-${annee} à ${heure}h${minute}`;
            return date;
        },
        newCapital() {
            let montantRetraits = this.retraitsList.reduce(function (sum, current) {
                return sum + current.total * 1;
            }, 0);

            let ancienCapital = this.$thousandFormater(
                this.inventaireData.capital_precedent
            );

            return ancienCapital + this.benefice - montantRetraits;
        },
        benefice() {
            // Calcul Bénéfice = (valeur du stock + mt en caisse + reste à recouvrer + mt comptes + retaits associés) - (Dédommagements + CI + Frais + Chargeur + Génération facture) - Capital précédent
            let montantComptes = 0;
            if(this.deviseLoaded && this.inventaireDataLoaded){
                this.accountsList.forEach((element) => {
                    montantComptes +=
                        element.solde *
                        this.devises.find((_dev) => _dev.name == element.devise).value;
                });

                let montantRetraits = this.retraitsList.reduce(function (sum, current) {
                    return sum + current.total * 1;
                }, 0);
                console.log("in benefice")
                let benefice =
                    this.inventaireData.valeur_stock +
                    this.inventaireData.cash +
                    this.inventaireData.reste_sur_avance +
                    montantComptes +
                    montantRetraits -
                    this.inventaireData.total_dettes -
                    parseInt(this.inventaireData.capital_precedent);
                return benefice;
            }
        },
        ...mapState({
            user: (state) => state.user.auth,
        }),
        totalRows() {
            // Set the initial number of items
            return this.associes.length;
        },
        imgUrl() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.sideImg = require("@/assets/images/association.png");
            return this.sideImg;
        },
    },
    created() {
        this.showAssociation();
        this.getDevisesList();
        this.getComptes();
    },
    mounted() {},
    methods: {
        addsInventaires() {
            this.$router.push({
                name: "inventaire-nouveau",
                params: {
                    id: this.$router.currentRoute.params.id,
                    dernier_inventaire: this.dateDernierInventaire,
                    association: this.association.name,
                },
            });
        },

        resetInputInventaire() {
            this.resetInputLastDateInventaire();
            this.resetInputCurrentDateInventaire();
        },

        resetInputLastDateInventaire() {
            if (this.errorsLastDateInventaire) {
                this.errorsLastDateInventaire = null;
            }
        },

        resetInputCurrentDateInventaire() {
            if (this.errorsCurrentDateInventaire) {
                this.errorsCurrentDateInventaire = null;
            }
        },

        checkAccountGestionnaire() {},

        openAddCompte() {
            this.addCompteModal = true;
        },

        addCompte(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.$refs.addCompteForm.validate().then((success) => {
                if (success) {
                    if (this.accountGestionnaire) {
                        this.editAssocie();
                    } else {
                        this.addAcheteur();
                    }
                }
            });
        },
        etatAdd() {
            this.add = true;
            this.empty = false;
        },
        checkAcheteur() {
            let acheteur = this.acheteurs.find(
                (_ach) => _ach.name == this.acheteurForm.name
            );
            if (acheteur) {
                let acheteurAccount = this.comptes.find(
                    (_acc) => _acc.id == acheteur.id
                );
                if (acheteur.devise) this.acheteurForm.devise = acheteur.devise;
                else if (acheteurAccount)
                    this.acheteurForm.devise = this.devises.find(
                        (_dev) => _dev.name == acheteurAccount.devise
                    ).id;
                this.acheteurExist = true;
            } else {
                this.acheteurExist = false;
            }
        },

        resetForm() {
            this.defaultForm.name = "";
            this.defaultForm.code = "";
        },
        resetInput() {
            this.resetInputName();
            this.resetInputCode();
        },
        resetInputName() {
            if (this.errorsName) {
                this.errorsName = null;
            }
        },
        resetInputCode() {
            if (this.errorsLastDateInventaire) {
                this.errorsLastDateInventaire = null;
            }
        },
        resetModal() {
            this.editedForm.name = "";
            this.editedForm.code = "";
            this.resetInputModal();
        },
        resetInputModal() {
            this.resetInputNameModal();
            this.resetInputCodeModal();
        },
        resetInputNameModal() {
            if (this.errorsNameModal) {
                this.errorsNameModal = null;
            }
        },
        resetInputCodeModal() {
            if (this.errorsCodeModal) {
                this.errorsCodeModal = null;
            }
        },
        showToast(titre, type) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: titre,
                    icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
                    variant: type,
                },
            });
        },

        showAssociation() {
            let id = this.$router.currentRoute.params.id;
            this.association_id = this.$router.currentRoute.params.id;
            this.$http
                .get("/associations/" + this.association_id)
                .then((res) => {
                    this.association = res.data.data;
                    this.AssociationAssocie = res.data.data.acheteurs_associes.filter(
                        (element) => {
                            return element.type == "associe";
                        }
                    );
                    this.associes = res.data.data.acheteurs_associes;
                    this.accountsList = [];
                    res.data.data.comptes.forEach((account) => {
                        if (account.author_type == "acheteur") {
                            this.getSolde(account);
                        }

                        let associeName = {
                            name: this.associes.find((_ass) => _ass.id == account.author_id)
                                .name,
                        };
                        let toMerge = {
                            ...associeName,
                            ...account,
                        };
                        this.accountsList.push(toMerge);

                        if (account.id == this.association.associe_id) {
                            associe.isDisabled = true;
                        }
                    });

                    if (this.association.retraits) {
                        this.retraitsList = this.association.retraits;
                    }
                    if (this.association.benefice) {
                        let benefice = this.association.benefice;
                        if (benefice.original.success) {
                            this.inventaireData = benefice.original.data;
                            this.inventaireDataLoaded = true
                            this.inventaireData.total_dettes =
                                this.inventaireData.total_cartes_imp +
                                this.inventaireData.total_chargeurs +
                                this.inventaireData.total_frais +
                                this.inventaireData.refunds +
                                this.inventaireData.total_generation_facture;
                        } else {
                            this.alertMessage = benefice.original.message;
                            this.alertShow = true;
                            this.inventaireData = {};
                        }
                    }
                })
                .catch((e) => {
                    console.log("erreur de récupération:" + e);
                });

            this.getAcheteurs();
        },

        getSolde(account) {
            let operations = account.operations;
            let entree = 0;
            let sortie = 0;
            if (operations.length > 0) {
                operations
                    .slice()
                    .reverse()
                    .forEach((element, index) => {
                        if (index == 0) {
                            element.solde = element.entree ? element.entree : -element.sortie;
                        } else if (index > 0) {
                            if (element.entree) {
                                element.solde =
                                    operations[operations.length - index].solde + element.entree;
                            } else if (element.sortie) {
                                element.solde =
                                    operations[operations.length - index].solde - element.sortie;
                            } else {
                                element.solde = operations[operations.length - index].solde + 0;
                            }
                        }
                    });
            }

            operations.forEach((element) => {
                if (element.entree) entree += element.entree;
                if (element.sortie) sortie += element.sortie;
            });

            return (account.solde = entree - sortie);
        },
        showAccount(item) {
            window.sessionStorage.setItem("compte_id", item.id);
            this.$router.push({
                name: "compte-details",
            });
        },
        associeModal() {
            this.getAssocies();
            this.SetDeviseEnable(this.devises);
            this.items = [];
            this.selectedDevises = [];
            this.modalAdd = true;
        },
        acheteurModal() {
            // this.SetDeviseEnable(this.devises);
            // this.items = [];
            // this.selectedDevises = [];
            this.modalAddAcheteur = true;
        },
        associePrincipalModal() {
            this.getAssocies();
            if (this.association.associe_id) {
                //this.AssociePrincipal.associe = this.association.associe_id
                this.addPrincipalFormulaire.associe = this.association.associe_id;
                this.AssociationAssocie.forEach((associe) => {
                    if (associe.id == this.association.associe_id) {
                        associe.isDisabled = true;
                    }
                });
            }

            this.modalAddPrincipal = true;
        },
        associeDetailModal(item) {
            this.detailFormulaire = item;
            const associe = this.allAssocies.find(
                ({ id }) => id === item.pivot.associe_id
            );
            this.modalDetail = true;
        },
        associeEditModal(item) {
            this.getAssocies();
            this.selectedDevises = [];
            this.editFormulaire = item;
            this.SetDeviseEnable(this.devises);
            this.editFormulaire.comptes.forEach((element) => {
                this.devises.forEach((devise) => {
                    if (devise.name == element.devise) {
                        this.selectedDevises.push({
                            index: "A",
                            devise: devise,
                        });
                    }
                });
            });
            this.editItems = this.editFormulaire.comptes;
            (this.editItems2 = []), (this.modalEdit = true);
        },
        SetDeviseEnable(devises) {
            this.devises.forEach((devise) => {
                devise.isDisabled = false;
            });
        },
        getAssocies() {
            this.$http
                .get("/associes")
                .then((res) => {
                    this.allAssocies = res.data.data.reverse();
                    this.filterAssocies = [];
                    this.allAssocies.forEach((element1) => {
                        var result = "";
                        this.associes.forEach((element2) => {
                            if (element1.id === element2.id) {
                                result = element1;
                            }
                        });
                        if (!result) {
                            this.filterAssocies.push(element1);
                        }
                    });
                })
                .catch((e) => {
                    console.log("erreur de récupération:" + e.response.data.message);
                });
        },
        getAcheteurs() {
            this.$http
                .get("/acheteurs")
                .then((res) => {
                    this.acheteurs = res.data.data;
                    this.acheteurs.forEach((element) => {
                        element.text = element.name;
                    });
                })
                .catch((e) => {
                    console.log("erreur de récupération:" + e.response.data.message);
                });
        },
        formatAmounts(valeurs) {
            valeurs = JSON.parse(JSON.stringify(valeurs));
            valeurs.forEach((element) => {
                if (element.resteInitial) {
                    element.resteInitial = this.$thousandFormater(element.resteInitial);
                }
                if (element.solde) {
                    element.solde = this.$thousandFormater(element.solde);
                }
            });

            return valeurs;
        },

        addAcheteur() {
            this.isOperating = true;
            if (this.acheteurExist) {
                let acheteur = this.acheteurs.find(
                    (_ach) => _ach.name == this.acheteurForm.name
                );
                this.acheteurForm.id = acheteur.id;
                this.acheteurForm.associations = acheteur.associations;
                this.acheteurForm.associations.push({
                    name: this.association.name,
                    id: this.association.id,
                });
                this.$http
                    .put("/acheteurs/" + this.acheteurForm.id, this.acheteurForm)
                    .then((result) => {
                        this.isOperating = false;
                        if (result.data.success) {
                            this.addCompteModal = false;
                            this.$refs.addCompteForm.reset();
                            this.acheteurForm = {};
                            this.showToast("Acheteur ajouté avec succès", "success");
                            this.showAssociation();
                        } else {
                            this.showToast("Veuillez remplir tous les champs", "danger");
                            this.showToast(result.data.message, "danger");
                        }
                    })
                    .catch((e) => {
                        this.isOperating = false;
                        if (!e.response) {
                            this.showToast(e, "danger");
                        }
                    });
            } else {
                this.acheteurForm.associations = [
                    {
                        name: this.association.name,
                        id: this.association.id,
                    },
                ];
                this.$http
                    .post("/acheteurs", this.acheteurForm)
                    .then((result) => {
                        this.isOperating = false;
                        if (result.data.success) {
                            this.addCompteModal = false;
                            this.acheteurForm = {};
                            this.$refs.addCompteForm.reset();
                            this.showToast("Acheteur ajouté avec succès", "success");
                            this.showAssociation();
                        } else {
                            this.showToast("Veuillez remplir tous les champs", "danger");
                            this.showToast(result.message, "danger");
                        }
                    })
                    .catch((e) => {
                        this.isOperating = false;
                        if (!e.response) {
                            this.showToast(e, "danger");
                        }
                    });
            }
        },

        addAssocie(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.$refs.addForm.validate().then((success) => {
                if (success) {
                    this.addFormulaire.association = this.association_id;
                    this.addFormulaire.comptes = this.items;

                    for (var i = 0; i < this.items.length; i++) {
                        for (var j = i + 1; j < this.addFormulaire.comptes.length; j++) {
                            if (
                                this.items[i].libelle === this.addFormulaire.comptes[j].libelle
                            ) {
                                this.showToast(
                                    "02 comptes ont le même libellé ,veuillez corriger cela",
                                    "danger"
                                );
                                return false;
                            }
                            if (
                                this.items[i].devise === this.addFormulaire.comptes[j].devise
                            ) {
                                this.showToast(
                                    "02 comptes ont la même devise ,veuillez corriger cela",
                                    "danger"
                                );
                                return false;
                            }
                        }
                    }

                    this.addFormulaire.cap_invest = this.$thousandFormater(
                        this.addFormulaire.cap_invest
                    );
                    this.addFormulaire.comptes = this.formatAmounts(this.items);
                    this.isOperating = true;
                    this.$http
                        .post("/addAssocies", this.addFormulaire)
                        .then((result) => {
                            this.isOperating = false;
                            if (result.data.success) {
                                this.resetInput();
                                this.addFormulaire = JSON.parse(
                                    JSON.stringify({ type: Object, default: () => {} })
                                );
                                this.add = false;
                                this.modalAdd = false;
                                this.showAssociation();
                                this.showToast("Associé ajouté avec succès", "success");
                            } else {
                                this.showToast(result.data.message, "danger");
                            }
                        })
                        .catch((e) => {
                            this.isOperating = false;
                            this.showToast(e.response.data.message, "danger");
                        });
                }
            });
        },
        deleteAssocie(item) {
            this.$bvModal
                .msgBoxConfirm(
                    "Voulez-vous vraiment supprimer cet enregistrement de la liste des associés ?",
                    {
                        title: "Supprimer un associé",
                        okVariant: "danger",
                        cancelVariant: "outline-secondary",
                        okTitle: "Confirmer",
                        cancelTitle: "Annuler",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((value) => {
                    if (value === true) {
                        this.$http
                            .delete("/deleteAssocies/" + item.id + "/" + this.association_id)
                            .then((result) => {
                                if (result.data.success) {
                                    this.showToast("Supression effectuée avec succès", "success");
                                    this.showAssociation();
                                } else {
                                    this.showToast(result.data.message, "danger");
                                }
                            })
                            .catch((err) => {
                                this.showToast(err.response, "error");
                            });
                    }
                });
        },
        addAssociePrincipal(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.$refs.addPrincipalForm.validate().then((success) => {
                if (success) {
                    this.addPrincipalFormulaire.association = this.association_id;
                    this.isOperating = true;
                    this.$http
                        .post("/addAssociePrincipal", this.addPrincipalFormulaire)
                        .then((result) => {
                            this.isOperating = false;
                            if (result.data.success) {
                                this.modalAddPrincipal = false;
                                this.addPrincipalFormulaire.associe = result.data.data.id;
                                this.AssociationAssocie.forEach((associe) => {
                                    if (associe.id == this.addPrincipalFormulaire.associe) {
                                        associe.isDisabled = true;
                                    }
                                });
                                this.showAssociation();
                                this.showToast(
                                    "Associé principal défini avec succès",
                                    "success"
                                );
                            } else {
                                this.showToast(result.message, "danger");
                            }
                        })
                        .catch((e) => {
                            this.isOperating = false;
                            if (!e.response) {
                                this.showToast(e, "danger");
                            }
                        });
                }
            });
        },
        getDevisesList() {
            this.$http.get("/devises").then((response) => {
                this.deviseLoaded = true,
                    this.devises = response.data.data;
                this.devisePrincipal = this.devises.find(
                    ({ devise_principale }) => devise_principale === 1
                );
            });
        },
        getComptes() {
            this.$http.get("/comptes").then((response) => {
                this.comptes = response.data.data;
            });
        },
        refreshDevise(item, sendedindex, formType) {
            const devise = this.devises.find(({ id }) => id === item.devise);
            let selectedIndex = this.selectedDevises.find(
                ({ index }) => index === sendedindex
            );
            if (selectedIndex) {
                this.selectedDevises = this.selectedDevises.filter((element) => {
                    return element.index !== sendedindex;
                });
            }
            if (devise) {
                this.selectedDevises.push({
                    index: sendedindex,
                    devise: devise,
                });
            }
            if (formType == "add") {
                // this.fillLabelImputByDevise(item, sendedindex);
            } else if (formType == "edit") {
                // this.fillLabelImputByDeviseEdit(item, sendedindex);
            }
        },
        checkIsSelected(id_devise) {
            return (
                this.selectedDevises.find(({ devise }) => devise.id === id_devise) !=
                null
            );
        },
        checkFormValidity() {
            const valid = this.$refs.addForm.checkValidity();
            this.nameState = valid;
            return valid;
        },
        repeateAgain(items) {
            items.push({
                id: (this.nextTodoId += this.nextTodoId),
            });
        },
        removeItem(index, items) {
            const check = this.devises.find(({ id }) => id === items[index].devise);
            if (check) {
                check.isDisabled = false;
            }
            items.splice(index, 1);
        },
        fillLabelImput() {
            if (this.addFormulaire.associe) {
                this.items.forEach((item, index) => {
                    if (this.addFormulaire.associe != null && item.devise != null) {
                        const associe = this.allAssocies.find(
                            ({ id }) => id === this.addFormulaire.associe
                        );
                        const devise = this.devises.find(({ id }) => id === item.devise);
                        var associeName = "";

                        if (associe) {
                            associeName = associe.name;
                        } else {
                            associeName = this.addFormulaire.associe;
                        }

                        item.libelle =
                            devise.name +
                            "-" +
                            associeName +
                            "(" +
                            this.association.code +
                            ")";
                        document
                            .getElementById("libelle" + index)
                            .dispatchEvent(new Event("blur"));
                    }
                });
            }
        },

        fillLabelImputByDevise(item, index) {
            if (this.addFormulaire.associe) {
                if (this.addFormulaire.associe != "") {
                    const associe = this.allAssocies.find(
                        ({ id }) => id === this.addFormulaire.associe
                    );
                    const devise = this.devises.find(({ id }) => id === item.devise);

                    var associeName = "";

                    if (associe) {
                        associeName = associe.name;
                    } else {
                        associeName = this.addFormulaire.associe;
                    }

                    item.libelle =
                        devise.name + "-" + associeName + "(" + this.association.code + ")";
                    document
                        .getElementById("libelle" + index)
                        .dispatchEvent(new Event("blur"));
                }
            }
        },

        fillLabelImputByDeviseEdit(item, index) {
            if (this.editFormulaire.name) {
                if (this.editFormulaire.name != "") {
                    const associe = this.allAssocies.find(
                        ({ name }) => name === this.editFormulaire.name
                    );
                    const devise = this.devises.find(({ id }) => id === item.devise);
                    item.libelle =
                        devise.name +
                        "-" +
                        associe.name +
                        "(" +
                        this.association.code +
                        ")";
                    document
                        .getElementById("libelleEdit" + index)
                        .dispatchEvent(new Event("blur"));
                }
            }
        },
        editAssocie() {
            // bvModalEvt.preventDefault();
            this.$refs.addCompteForm.validate().then((success) => {
                if (success) {
                    this.associeForm.association = this.association_id;
                    // this.associeForm.comptes = this.editItems;
                    // this.editFormulaire.new_comptes = {

                    // };
                    this.isOperating = true;
                    // this.associeForm.associeForm = this.formatAmounts(this.editItems2);
                    this.$http
                        .post("/updateAssocie", this.associeForm)
                        .then((result) => {
                            this.isOperating = false;
                            if (result.data.success) {
                                this.addCompteModal = false;
                                this.$refs.addCompteForm.reset();
                                this.associeForm = {};
                                this.showAssociation();
                                this.showToast("Compte ajouté avec succès", "success");
                                // this.modalEdit = false;
                            } else {
                                this.showToast(result.data.message, "danger");
                            }
                        })
                        .catch((e) => {
                            this.showToast(e.response.data.message, "danger");
                            this.isOperating = false;
                            // this.showToast(e.response.data.errors, "danger");
                            //   var nbrItems = this.editItems2.length;
                            // for (var i = 0; i < nbrItems; i++) {
                            //   this.errorsLibelleEdit[i] =
                            //     e.response.data["new_comptes." + i + ".libelle"];
                            // }
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.e-input-group {
    border-color: rgb(0 0 0 / 15%) !important;
    border-width: 1px !important;
    border-radius: 5px !important;
    color: rgb(127 125 139) !important;
    line-height: 2.1 !important;
    padding-left: 15px !important;
}

.e-input-group::before,
.e-input-group::after {
    background: #e3165b00 !important;
}

.detail_organisation {
    height: 200px;
}

.img-fluid {
    max-width: 40% !important;
}

.icone {
    max-width: 12% !important;
}
</style>
